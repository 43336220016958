import { Button, Icon, Input } from '@momentum-ui/react'
import { buildClientSchema, getIntrospectionQuery } from 'graphql'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useExpandTryOut } from '../../../hooks/useExpandTryOut'
import {
  IRestDocumentation,
  IRestParameters,
} from '../../../interfaces/restInterfaces'
import { generateTrackingId } from '../../../services/httpService'
import { logError } from '../../../services/loggerService'
import {
  assembleCurlStatement,
  bucketParameters,
  determineParamType,
  prepareEndpoint,
  prepareQueryParameters,
  sortParameters,
} from '../../../utils/tryout.utils'
import BearerTokenInput from '../../BearerToken'
import CodeSnippetHeader from '../../CodeSnippet/CodeSnippetHeader'
import GraphiQLPlayGround from '../../GraphiQLPlayGround'

interface IGraphQLTryOutProps {
  api: IRestDocumentation
  name: string
}

export const fetchSchema = async (
  authToken: string,
  config: { [key: string]: any },
  stateCb: Function
): Promise<any> => {
  if (!authToken) {
    stateCb(null)
    return
  }
  try {
    const postBody = JSON.stringify({
      query: getIntrospectionQuery(),
    })
    const response = await fetch(config.url, {
      method: 'post',
      headers: config.headers,
      body: postBody,
    })

    const introspectionResult = await response.json()

    const finalSchema = buildClientSchema(introspectionResult.data)
    if (finalSchema) {
      stateCb(finalSchema)
    } else {
      stateCb(null)
    }
  } catch (err) {
    logError(`${err}`)
    stateCb(null)
  }
}

const GraphQLTryOut: React.FC<IGraphQLTryOutProps> = ({ api }) => {
  const [isTryOutExpanded, toggleTryout] = useExpandTryOut()
  const { t } = useTranslation()
  const [tokenFromInput, setTokenFromInput] = useState('')

  api.parameters = sortParameters(api.parameters)

  const [apiDetails, setApiDetails] = useState(api)
  const [curlStatement, setCurlStatement] = useState(() =>
    assembleCurlStatement(api, tokenFromInput)
  )
  const [schema, setSchema] = useState<any>(null)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${tokenFromInput}`,
    trackingId: generateTrackingId(),
  }

  const getUrl = (): string => {
    const { parameters, endpoint } = apiDetails
    const bucketedParameters = bucketParameters(parameters)
    const preparedEndpoint = prepareEndpoint(endpoint, bucketedParameters)
    const preparedQueryParameters = prepareQueryParameters(bucketedParameters)
    const preparedUrl = `${process.env.GATSBY_BFF_BASE_URL}proxy${preparedEndpoint}`
    return Object.keys(preparedQueryParameters).length
      ? `${preparedUrl}?${qs.stringify(preparedQueryParameters)}`
      : preparedUrl
  }

  useEffect(() => {
    const requestConfig = {
      url: getUrl(),
      headers,
    }
    fetchSchema(tokenFromInput, requestConfig, setSchema)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFromInput])

  const handleAuthTokenInput = (token: string): void => {
    if (token !== tokenFromInput) {
      setTokenFromInput(token)
      setCurlStatement(assembleCurlStatement(apiDetails, token))
    }
  }

  const displayParameterInputs = (): React.ReactNode => {
    return (
      <div className="parameters-container">
        <p className="inputs-header">{t('tryOut.parameters')}</p>
        {apiDetails.parameters?.map(
          (param: IRestParameters, index) =>
            (param.in === 'query' || param.in === 'path') && (
              <div key={index} className="input-outer-box">
                <div className="label-box">
                  <div className="param-name">
                    <span>{param.name}</span>
                    {param.required && <span className="required">*</span>}
                  </div>
                  <span className="param-type">
                    {determineParamType(param)}
                  </span>
                </div>
                <Input
                  name={param.name}
                  placeholder={
                    param?.example?.toString() ||
                    param.schema?.example?.toString()
                  }
                  onChange={(input: any): void => {
                    const paramsCopy = apiDetails.parameters?.slice(0)
                    const theIndex = paramsCopy?.findIndex(
                      (el) => el.name === param.name
                    )
                    paramsCopy![theIndex!].value = input.target.value

                    setApiDetails({
                      ...apiDetails,
                      parameters: paramsCopy,
                    })

                    setCurlStatement(
                      assembleCurlStatement(apiDetails, tokenFromInput)
                    )
                  }}
                />
              </div>
            )
        )}
      </div>
    )
  }

  const updateCurlStatement = (req: any): void => {
    const newApiDetails = {
      ...apiDetails,
      requestBody: {
        ...apiDetails.requestBody!,
        value: JSON.stringify(req),
      },
    }

    setCurlStatement(assembleCurlStatement(newApiDetails, tokenFromInput))
  }

  const displayGraphiQLPlayground = (): React.ReactNode => {
    return (
      <>
        <p className="code-snippet-header">GraphiQL</p>
        {isTryOutExpanded || window.innerWidth < 1024 ? (
          <div className="graphiql-playground">
            <GraphiQLPlayGround
              token={tokenFromInput}
              url={getUrl()}
              updateCurl={updateCurlStatement}
              schema={schema}
            />
            {!tokenFromInput ? (
              <>
                <div className="auth-token-message">
                  <span>
                    {t('bearerTokenInputComponent.authTokenMissingMessage')}
                  </span>
                </div>
                <div className="graphiql-overlay-disabled"></div>
              </>
            ) : null}
          </div>
        ) : (
          <div className="playground-preview flex-horizontal-and-vertical-center">
            <div>
              <p>{t('tryOut.gqlExpandMsg')}</p>
              <Button color="blue" onClick={toggleTryout}>
                <Icon name="icon-fullscreen_16" />
                {t('tryOut.maximize')}
              </Button>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <form className="try-out graphql-try-out">
        <CodeSnippetHeader
          canCopy={true}
          copyText={curlStatement}
          headerContentsLeftSide={
            <span>{t('generalDocumentation.request')}</span>
          }
        />
        <div className="graphql-body body">
          <p className="inputs-header">{t('tryOut.header')}</p>
          <BearerTokenInput
            handleAuthTokenInput={handleAuthTokenInput}
            toggleId="graphQLAuthToken"
          />
          <hr className="try-out-divider" />
          {apiDetails.parameters?.length ? displayParameterInputs() : null}
        </div>
      </form>
      {apiDetails.requestBody ? displayGraphiQLPlayground() : null}
    </>
  )
}

export default GraphQLTryOut
